<template>
  <div></div>
</template>

<script>
export default {
  created () {
    window.open('https://www.showjob.app/static/js/app-release.apk')
    this.gotoUrl('index')
  }
}
</script>
